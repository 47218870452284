import React, { Component } from 'react';
import utils from "../../utils";
import apiMyAccount from "../../api/MyAccount";
import { FormGroup, Input, Label, Row, Col, Button } from "reactstrap";
import InputCurrency from "../../components/Vietluck/InputCurrency";
import ButtonLoading from "../../components/FworkTag/ButtonLoading";

import PushNotify from "../../components/Vietluck/PushNotify";

import { connect } from "react-redux";
import LoadingPage from "../../components/LoadingPage";

const logo = {
    vnpay: "/img/logo/vnpayqr.png",
    vietcombank: "/img/logo/vietcombank.png",
    sacombank: "/img/logo/sacombank.png",
    viettel: require('../../assets/images/icon/viettelpay.png'),
    zalo: require('../../assets/images/icon/zalopay.png'),
    momo: require('../../assets/images/icon/momo.png'),
    "Ngân hàng MB": require('../../assets/images/icon/mbbank.png'),
    "Ngân hàng ACB": require('../../assets/images/icon/acbbank.png'),
    "TECHCOMBANK": require('../../assets/images/logo/Logo-TCB-H.webp'),
    'VIETINBANK':require('../../assets/images/logo/VietinBank-Logo-PNG-1.png')
};
class RechargeMegaPay extends React.Component {
    constructor(props) {
        super(props);
        this.listMoneySuggest = [
            {
                value: 50,
                name: "50.000",
            },
            {
                value: 100,
                name: "100.000",
            },
            {
                value: 200,
                name: "200.000",
            },
            {
                value: 500,
                name: "500.000",
            },
        ];
        this.listBank = [
            { value: "VIETCOMBANK", name: "Vietcombank" },
            { value: "VIETINBANK", name: "Vietinbank" },
            { value: "BIDV", name: "BIDV" },
            { value: "AGRIBANK", name: "Agribank" },
            { value: "SACOMBANK", name: "SacomBank" },
            { value: "TECHCOMBANK", name: "TechcomBank" },
            { value: "ACB", name: "Ngân hàng ACB" },
            { value: "VPBANK", name: "VPBank" },
            { value: "DONGABANK", name: "DongABank" },
            { value: "EXIMBANK", name: "Ngân hàng EximBank" },
            { value: "TPBANK", name: "TPBank" },
            { value: "NCB", name: "Ngân hàng Quốc dân (NCB)" },
            { value: "OJB", name: "OceanBank" },
            { value: "MSBANK", name: "Ngân hàng Hàng Hải (MSBANK)" },
            { value: "HDBANK", name: "HDBank" },
            { value: "NAMABANK", name: "NamABank" },
            { value: "OCB", name: "Ngân hàng Phương Đông (OCB)" },
            { value: "SCB", name: "Ngân hàng TMCP Sài Gòn (SCB)" },
            { value: "IVB", name: "Ngân hàng TNHH Indovina (IVB)" },
            { value: "ABBANK", name: "ABBANK" },
            { value: "SHB", name: "SHB" },
            { value: "VIB", name: "VIB" },
            { value: "VNMART", name: "Ví điện tử VnMart" },
        ];
        this.listPaymentMethod = [
            { value: 1, name: "Thẻ ATM và tài khoản ngân hàng" },
        ];

        this.state = {
            amount: 0,
            bankCode: "VIETCOMBANK",
            paymentMethod: 1,

            isLoading: false,
        };
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value,
        });
    }

    handleChangeMoney(amount) {
        this.setState({ amount: amount });
    }

    toggle(component) {
        this.setState({
            [component]: !this.state[component],
        });
    }

    handleSubmit() {
        if (utils.isEmpty(this.state.amount) || this.state.amount === 0) {
            utils.alert("Nhập số tiền");
            return;
        }

        if (this.state.amount % 1000 !== 0) {
            utils.alert("Số tiền phải là bội của 1000");
            return;
        }
        if (this.state.amount < 10000) {
            utils.alert("Số tiền tối thiểu nạp vào là 10.000 đ");
            return;
        }
        this.setState({ isLoading: true });
        apiMyAccount.megaPaymentRecharge(
            {
                amount: this.state.amount,

            },
            (err, result) => {
                if (err) {
                    this.setState({ isLoading: false });
                } else {
                    this.setState({ isLoading: false });

                    //window.open(result.data, "_blank")
                    window.location.replace(result.payUrl);
                }
            }
        );
    }

    render() {
        return (
            <div className="bootstrap-reset p-2">

                <FormGroup className="mt-3">
                    <Label>Loại thanh toán</Label>
                    <Input
                        type="select"
                        onChange={this.handleChange.bind(this)}
                        name="paymentMethod"
                    >
                        {this.listPaymentMethod.map(({ name, value }, index) => (
                            <option key={index} value={value}>
                                {name}
                            </option>
                        ))}
                    </Input>
                </FormGroup>

                <FormGroup>
                    <Label>Số tiền</Label>
                    <InputCurrency
                        onChange={this.handleChangeMoney.bind(this)}
                        name="amount"
                    />
                </FormGroup>
                {/*<Row>*/}
                {/*    {*/}
                {/*        this.listMoneySuggest.map(({name, value}, index) => (*/}
                {/*            <Button outline className="w-25">{name}</Button>*/}
                {/*        ))*/}
                {/*    }*/}
                {/*</Row>*/}
                <ButtonLoading
                    title="Tiếp tục"
                    loading={this.state.isLoading}
                    onClick={this.handleSubmit.bind(this)}
                    className={"w-100"}
                />
                <div style={{ color: 'red' }}> (*) Chú ý: Nếu sau 4h tài khoản vẫn chưa được nạp vui lòng liên hệ bộ phận Hỗ trợ khách hàng 0835.556.556</div>
            </div>
        );
    }
}
class RechargeVNPAY extends React.Component {

    constructor(props) {
        super(props);
        this.listMoneySuggest = [
            {
                value: 50,
                name: "50.000"
            },
            {
                value: 100,
                name: "100.000"
            },
            {
                value: 200,
                name: "200.000"
            },
            {
                value: 500,
                name: "500.000"
            }
        ];
        this.listBank = [
            { value: "VIETCOMBANK", name: "Vietcombank" },
            { value: "VIETINBANK", name: "Vietinbank" },
            { value: "BIDV", name: "BIDV" },
            { value: "AGRIBANK", name: "Agribank" },
            { value: "SACOMBANK", name: "SacomBank" },
            { value: "TECHCOMBANK", name: "TechcomBank" },
            { value: "ACB", name: "Ngân hàng ACB" },
            { value: "VPBANK", name: "VPBank" },
            { value: "DONGABANK", name: "DongABank" },
            { value: "EXIMBANK", name: "Ngân hàng EximBank" },
            { value: "TPBANK", name: "TPBank" },
            { value: "NCB", name: "Ngân hàng Quốc dân (NCB)" },
            { value: "OJB", name: "OceanBank" },
            { value: "MSBANK", name: "Ngân hàng Hàng Hải (MSBANK)" },
            { value: "HDBANK", name: "HDBank" },
            { value: "NAMABANK", name: "NamABank" },
            { value: "OCB", name: "Ngân hàng Phương Đông (OCB)" },
            { value: "SCB", name: "Ngân hàng TMCP Sài Gòn (SCB)" },
            { value: "IVB", name: "Ngân hàng TNHH Indovina (IVB)" },
            { value: "ABBANK", name: "ABBANK" },
            { value: "SHB", name: "SHB" },
            { value: "VIB", name: "VIB" },
            { value: "VNMART", name: "Ví điện tử VnMart" },
        ];
        this.listPaymentMethod = [{ value: 1, name: "Thẻ ATM và tài khoản ngân hàng" }];

        this.state = {
            amount: 0,
            bankCode: "VIETCOMBANK",
            paymentMethod: 1,

            isLoading: false

        }
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleChangeMoney(amount) {
        this.setState({ amount: amount })
    }

    toggle(component) {
        this.setState({
            [component]: !this.state[component]
        });
    }

    handleSubmit() {
        if (utils.isEmpty(this.state.amount) || this.state.amount === 0) {
            utils.alert("Nhập số tiền");
            return;
        }

        if (this.state.amount % 1000 !== 0) {
            utils.alert("Số tiền phải là bội của 1000");
            return;
        }

        this.setState({ isLoading: true });
        apiMyAccount.VNPAY_Payment({
            vnp_Amount: this.state.amount,
            vnp_BankCode: this.state.bankCode
        }, (err, result) => {
            if (err) {
                this.setState({ isLoading: false });
            } else {
                this.setState({ isLoading: false });

                //window.open(result.data, "_blank")
                window.location.replace(result.data)
            }
        });
    }

    render() {
        return (
            <div className="bootstrap-reset p-2">
                <Row className="justify-content-center">
                    <img src={logo["vnpay"]} alt="vnpay" style={{ height: "40px", width: "200px" }} />
                </Row>
                <FormGroup className="mt-3">
                    <Label>Loại thanh toán</Label>
                    <Input type="select" onChange={this.handleChange.bind(this)} name="paymentMethod">
                        {
                            this.listPaymentMethod.map(({ name, value }, index) => (
                                <option key={index} value={value}>
                                    {name}
                                </option>
                            ))
                        }
                    </Input>
                </FormGroup>
                <FormGroup>
                    <Label>Tên ngân hàng</Label>
                    <Input type="select" onChange={this.handleChange.bind(this)} name="bankCode">
                        {
                            this.listBank.map(({ name, value }, index) => (
                                <option key={index} value={value}>
                                    {name}
                                </option>
                            ))
                        }
                    </Input>
                </FormGroup>
                <FormGroup>
                    <Label>Số tiền</Label>
                    <InputCurrency onChange={this.handleChangeMoney.bind(this)} name="amount" />
                </FormGroup>
                {/*<Row>*/}
                {/*    {*/}
                {/*        this.listMoneySuggest.map(({name, value}, index) => (*/}
                {/*            <Button outline className="w-25">{name}</Button>*/}
                {/*        ))*/}
                {/*    }*/}
                {/*</Row>*/}
                <ButtonLoading
                    title="Tiếp tục"
                    loading={this.state.isLoading}
                    onClick={this.handleSubmit.bind(this)}
                    className={"w-100"}
                />
            </div>
        )
    }
}

class RechargeMomo extends React.Component {

    // constructor(props) {
    //     super(props);
    //     this.state = {
    //         isLoading: false,
    //         amount: 0
    //     }
    // }
    //
    // componentDidMount() {
    //     utils.confirm("Tính năng đang phát triển!", (check) => {
    //         window.location.replace("/recharge")
    //     })
    // }
    //
    // handleSubmit() {
    //     //
    //     if (this.state.isLoading) return;
    //     if (!this.state.amount) {
    //         utils.alert("Nhập số tiền");
    //         return;
    //     }
    //     //
    //     this.setState({isLoading: true});
    //     apiMyAccount.Momo_Payment({amount: this.state.amount}, (err, result) => {
    //         if(err) {
    //
    //         } else {
    //             // window.open(result.url);
    //             window.location.replace(result.url);
    //         }
    //     })
    // }
    //
    // handleChangeMoney(amount) {
    //     this.setState({amount: amount});
    // }
    //
    // render() {
    //     return (
    //         <div className="bootstrap-reset p-2">
    //             <Row className="justify-content-center mb-4">
    //                 <img src={logo["momo"]} alt="momo" style={{width: "60px", height: "60px"}}/>
    //             </Row>
    //             <FormGroup>
    //                 <Label>Số tiền</Label>
    //                 <InputCurrency onChange={this.handleChangeMoney.bind(this)}/>
    //             </FormGroup>
    //             <Row className="justify-content-center">
    //                 <ButtonLoading
    //                     title={"Xác nhận"}
    //                     loading={this.state.isLoading}
    //                     onClick={this.handleSubmit.bind(this)}
    //                 />
    //             </Row>
    //         </div>
    //     )
    // }
    constructor() {
        super();
        this.listBank = [
            {
                chu_tai_khoan: 'Trần Đăng Cường',
                so_tai_khoan: '0835556556',
            }
        ]
    }

    // componentDidMount() {
    //     utils.alert(
    //         `VESO888 hỗ trợ hình thức nạp tiền chuyển khoản qua ví Momo với nội dung chuyển khoản sau: NAP[SĐT]\r\nVí dụ: NAP${this.props.user.phoneNumber}\r\nYêu cầu chuyển tiền sẽ được xử lý tối đa trong 5 phút!`
    //     )
    // }

    handleCopyClipboard() {
        let copyText = document.querySelector("#copy-bank-account123");
        copyText.focus();
        copyText.select();
        document.execCommand("copy");
        PushNotify(1, "success", "Sao chép số điện thoại", "Đã sao chép")
    }

    render() {
        return (
            <div className="bootstrap-reset p-2">
                <div className="font-weight-bold" style={{ marginTop: "15", marginBottom: "10" }}>
                    Nạp tiền qua Ví MOMO
                </div>
                <div className="bank-box">
                    <div className="font-weight-bold text-danger justify-content-center">
                        Vui lòng thực hiện các bước sau để nạp tiền vào tài khoản:
                    </div>
                </div>
                <br></br>
                <div className="font-weight-bold">
                    1. Nhấn nút bên dưới để copy số điện thoại đăng ký tài khoản VESO888
                </div>
                <div className="bank-box">
                    <div className="font-weight-bold text-danger justify-content-center">
                        <Col className="text-center px-2">
                            <input style={{ position: 'absolute', top: -999 }} id="copy-bank-account123" readOnly value={"NAP" + this.props.user.phoneNumber} />
                            <Button className="w-100" onClick={this.handleCopyClipboard.bind(this)}>NAP {this.props.user.phoneNumber}</Button>
                        </Col>
                    </div>
                </div>
                <br></br>
                <div className="font-weight-bold">
                    2. Điền số điện thoại đăng ký VESO888 vào ô "Lời nhắn cho người nhận" trước khi thực hiện chuyển tiền trên ví MOMO.
                </div>
                <div className="bank-box">
                    <div className="font-weight-bold text-danger justify-content-center">
                        <Col className="text-center px-2">
                            <a href={"https://nhantien.momo.vn/0835556556"} target={"_blank"}>
                                <Button className="w-100">MỞ VÍ MOMO</Button>
                            </a>
                        </Col>
                    </div>
                </div>

                {/* <div className="px-4 py-2 text-center">
                    Hoặc quét mã QR
                    <img style={{width: "100%"}} src="/img/qr/vietluck-qr-momo.jpg" className="w-75" alt="momo" />
                    <div className="mt-1">
                        <a href={"/img/qr/vietluck-qr-momo.jpg"} download>
                            <Button>Lưu mã</Button>
                        </a>
                    </div>
                </div> */}

                <br></br>
                <div className="mt-2">
                    <h6>Lưu ý</h6>
                    <ul style={{ fontStyle: "italic" }}>
                        {/* <li>Giao dịch sẽ tự động hoàn thành khi bạn chuyển tiền. Thời gian hoàn thành giao dịch trong khoảng 15 giây tới tối đa 5 phút, kể từ lúc bạn chuyển tiền xong.</li> */}
                        <li>Nếu sau 5 phút kể từ lúc chuyển tiền tài khoản vẫn chưa được nạp, vui lòng liên hệ ZALO 0835.556.556 để được hỗ trợ trực tiếp.</li>
                    </ul>

                </div>
            </div>
        )
    }
}

class RechargeViettel extends React.Component {

    constructor() {
        super();
        this.listBank = [
            {
                chu_tai_khoan: 'Trịnh Đình Khánh',
                so_tai_khoan: '0784259259',
            }
        ]
    }

    componentDidMount() {
        utils.alert(
            `VietLuck hỗ trợ hình thức nạp tiền chuyển khoản qua ViettelPay với nội dung chuyển khoản sau: NAP[SĐT]\r\nVí dụ: NAP${this.props.user.phoneNumber}\r\nYêu cầu chuyển tiền sẽ được xử lý tối đa trong 15 phút!`
        )
    }

    handleCopyClipboard() {
        let copyText = document.querySelector("#copy-bank-account");
        copyText.select();
        document.execCommand("copy");
        PushNotify(1, "success", "Sao chép số tài khoản", "Đã sao chép")
    }

    render() {
        return (
            <div className="bootstrap-reset p-2">
                <div className="bank-box">
                    <div className="font-weight-bold">
                        VietLuck hỗ nạp tiền chuyển khoản qua ViettelPay với nội dung sau:
                    </div>
                    <Row className="font-weight-bold text-danger justify-content-center">
                        NAP[SĐT]
                    </Row>
                    <Row className="font-weight-bold justify-content-center">
                        Ví dụ: NAP{this.props.user.phoneNumber}
                    </Row>
                </div>
                <div className="font-weight-bold mt-4 mb-1">
                    Danh sách tài khoản Viettel
                </div>
                {
                    this.listBank.map((bank, index) => (
                        <div key={index} className="bank-box">
                            <Row className="justify-content-center">
                                <img src={logo[bank.bank]} alt={bank.bank} className="w-50" />
                            </Row>
                            <Row className="mt-2">
                                <Col>
                                    <Row>
                                        <span className="font-weight-bold">Chủ tài khoản:&nbsp;</span>
                                        {bank.chu_tai_khoan}</Row>
                                    <Row>
                                        <span className="font-weight-bold">STK:&nbsp;</span>
                                        <input readOnly id="copy-bank-account" value={bank.so_tai_khoan} />
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="mt-1 mb-2">
                                {/*<span className="btn-copy shadow">Sao chép</span>*/}
                                {/*<Button onClick={this.handleCopyClipboard.bind(this)}>Sao chép số TK</Button>*/}
                                <Col className="text-center px-2">
                                    <a href={"viettelpay://"}>
                                        <Button className="w-100">Mở ViettelPay</Button>
                                    </a>
                                </Col>
                                <Col className="text-center px-2">
                                    <Button className="w-100" onClick={this.handleCopyClipboard.bind(this)}>Sao chép số TK</Button>
                                </Col>
                            </Row>
                        </div>
                    ))
                }

                {/* <div className="px-4 py-2 text-center">
                    Hoặc quét mã QR
                    <img src="/img/qr/vietluck-qr-viettelpay.jpg" className="w-75" alt="viettelpay" />
                    <div className="mt-1">
                        <a href={"/img/qr/vietluck-qr-viettelpay.jpg"} download>
                            <Button>Lưu mã</Button>
                        </a>
                    </div>
                </div> */}
                <div className="mt-2">
                    (*) Yêu cầu của quý khách sẽ được xử lý trong tối đa 15 phút
                </div>
            </div>
        )
    }
}

class RechargeZalo extends React.Component {

    constructor() {
        super();
        this.listBank = [
            {
                chu_tai_khoan: 'VietLuck',
                so_tai_khoan: '0784259259',
            }
        ]
    }

    componentDidMount() {
        utils.alert(
            `VietLuck hỗ trợ hình thức nạp tiền chuyển khoản qua ZaloPay với nội dung chuyển khoản sau: NAP[SĐT]\r\nVí dụ: NAP${this.props.user.phoneNumber}\r\nYêu cầu chuyển tiền sẽ được xử lý tối đa trong 15 phút!`
        )
    }

    handleCopyClipboard() {
        let copyText = document.querySelector("#copy-bank-account");
        copyText.select();
        document.execCommand("copy");
        PushNotify(1, "success", "Sao chép số tài khoản", "Đã sao chép")
    }

    render() {
        return (
            <div className="bootstrap-reset p-2">
                <div className="bank-box">
                    <div className="font-weight-bold">
                        VietLuck hỗ nạp tiền chuyển khoản qua ZaloPay với nội dung sau:
                    </div>
                    <Row className="font-weight-bold text-danger justify-content-center">
                        NAP[SĐT]
                    </Row>
                    <Row className="font-weight-bold justify-content-center">
                        Ví dụ: NAP{this.props.user.phoneNumber}
                    </Row>
                </div>
                <div className="font-weight-bold mt-4 mb-1">
                    Danh sách tài khoản ZaloPay
                </div>
                {
                    this.listBank.map((bank, index) => (
                        <div key={index} className="bank-box">
                            <Row className="justify-content-center">
                                <img src={logo[bank.bank]} alt={bank.bank} className="w-50" />
                            </Row>
                            <Row className="mt-3">
                                <Col>
                                    <Row>
                                        <span className="font-weight-bold">Chủ tài khoản:&nbsp;</span>
                                        {bank.chu_tai_khoan}</Row>
                                    <Row>
                                        <span className="font-weight-bold">STK:&nbsp;</span>
                                        <input readOnly id="copy-bank-account" value={bank.so_tai_khoan} />
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="mt-1 mb-2">
                                {/*<span className="btn-copy shadow">Sao chép</span>*/}
                                <Col className="text-center px-2">
                                    <a href={"zalopay://"}>
                                        <Button className="w-100">Mở ZaloPay</Button>
                                    </a>
                                </Col>
                                <Col className="text-center px-2">
                                    <Button className="w-100" onClick={this.handleCopyClipboard.bind(this)}>Sao chép số TK</Button>
                                </Col>
                            </Row>
                        </div>
                    ))
                }

                <div className="px-4 py-2 text-center">
                    Hoặc quét mã QR
                    <img src="/img/qr/vietluck-qr-zalopay.jpg" className="w-75" alt="zalopay" />
                    <div className="mt-1">
                        <a href={"/img/qr/vietluck-qr-zalopay.jpg"} download>
                            <Button>Lưu mã</Button>
                        </a>
                    </div>
                </div>
                <div className="mt-2">
                    (*) Yêu cầu của quý khách sẽ được xử lý trong tối đa 15 phút
                </div>
            </div>
        )
    }
}

class RechargeBank extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            listBank: []
        }
    }

    componentDidMount() {
        fetch("/list-bank.json")
            .then(res => res.json())
            .then(res => {
                this.setState({ listBank: res })
            });
        utils.alert(
            ` <strong style="color:balck;font-weight: bold;font-size: 18px;">Khi nạp tiền với hình thức chuyển khoản ngân hàng: </strong> \r\n \r\n - Nội dung chuyển khoản: <b style="color:red;">NAP${this.props.user.phoneNumber}</b>\r\n \r\n - Thời gian chờ: trong khoảng 1 phút!`
        )
    }

    handleCopyClipboard(bank) {
        let copyText = document.querySelector("#copy-bank-" + bank);
        copyText.select();
        document.execCommand("copy");
        PushNotify(1, "success", "Sao chép số tài khoản", "Đã sao chép")
    }


    render() {
        return (
            <div className="bootstrap-reset p-2">
                <div className="bank-box">
                    <div className="font-weight-bold">
                        VESO888 hỗ nạp tiền qua hình thức chuyển khoản ngân hàng với nội dung sau:
                    </div>
                    <Row className="font-weight-bold justify-content-center">
                        NAP[SĐT]
                    </Row>
                    <Row className="font-weight-bold text-danger justify-content-center">
                        Ví dụ: NAP{this.props.user.phoneNumber}
                    </Row>
                </div>
                <div className="font-weight-bold mt-4 mb-1">
                    Danh sách tài khoản ngân hàng
                </div>
                {
                    this.state.listBank.map((bank, index) => (
                        <div key={index} className="bank-box my-2 ">
                            <Row className="justify-content-center">
                                <img src={logo[bank.bank]} alt={bank.bank} className="w-50" style={{ height: "50px" }} />
                            </Row>
                            <Row className="mt-3">
                                <Col>
                                    <Row>
                                        <span className="font-weight-bold">Chủ tài khoản:&nbsp;</span>
                                        {bank.chu_tai_khoan}</Row>
                                    <Row>
                                        <span className="font-weight-bold">Tên ngân hàng:&nbsp;</span>
                                        {bank.bank.toUpperCase()}
                                    </Row>
                                    <Row>
                                        <span className="font-weight-bold">STK:&nbsp;</span>
                                        <input readOnly className="copy-bank-account" id={`copy-bank-${bank.slug}`} value={bank.so_tai_khoan} />
                                    </Row>
                                    <Row>
                                        <span className="font-weight-bold">Chi nhánh:&nbsp;</span>
                                        {bank.chi_nhanh}
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="justify-content-end">
                                {/*<span className="btn-copy shadow">Sao chép</span>*/}
                                <Button onClick={this.handleCopyClipboard.bind(this, bank.slug)}>Sao chép số TK</Button>
                            </Row>

                        </div>
                    ))
                }
                <div className="mt-2">
                    (*) Yêu cầu của quý khách sẽ được xử lý trong tối đa 1 phút làm việc
                </div>
            </div>
        )
    }
}

class RechargeItems extends Component {

    constructor(props) {
        super(props);
        this.id = window.location.hash === "#2" ? 2 :
            window.location.hash === "#3" ? 3 :
                window.location.hash === "#4" ? 4 :
                    window.location.hash === "#5" ? 5 : window.location.hash === "#6"
                        ? 6 : 1;
        this.state = {
            isLoaded: false
        }
    }

    componentDidMount() {
        if (!(this.props.user.authToken && this.props.user.isActive)) {
            utils.confirm("Vui lòng đăng nhập", (check) => {
                window.location.replace("/auth/sign-in")
            })
        } else {
            this.setState({ isLoaded: true })
        }
    }

    render() {
        if (!this.state.isLoaded)
            return <LoadingPage />;
        switch (this.id) {
            case 1:
                return <RechargeVNPAY />;
            case 2:
                return <RechargeBank user={this.props.user} />;
            case 3:
                return <RechargeMomo user={this.props.user} />;
            case 4:
                return <RechargeViettel user={this.props.user} />;
            case 5:
                return <RechargeZalo user={this.props.user} />;
            case 6:
                return (

                    <RechargeMegaPay user={this.props.user} />

                );
            default:
                break;

        }
    }
}

function mapStateToProps(state) {
    return {
        user: state.user
    }
}

export default connect(mapStateToProps, {})(RechargeItems);
