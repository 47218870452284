import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'


const BoxAccount = ({ walletInfo, navigation }) => {
    return (
        <div className='shadow-light' style={{ borderRadius: 5, margin: 10, padding: 10, backgroundColor: '#fff', shadowColor: "#000" }}>
            <div style={style.AccountInfo}>
                <span style={style.TextAccount}>SỐ DƯ VÍ :</span>
                <span style={style.TextAccount}>
                    {FormatCurrency(walletInfo)} đ

                </span>
            </div>
            <div style={style.AccountNavigate}>
                <a style={style.MenuSection} href="/recharge">
                    <img
                        style={style.image}
                        src={require('../../assets/icons/wallet_blue.png')}
                    />
                    <span style={style.MenuText}>Nạp tiền</span>
                </a>
                <a href='/withdrawal' style={style.MenuSection}>
                    <img
                        style={style.image}
                        src={require('../../assets/icons/money_blue.png')}
                        
                    />
                    <span style={style.MenuText}>Rút tiền</span>
                </a>
                <a href='/payment-history' style={style.MenuSection}>
                    <img
                        style={style.image}
                        src={require('../../assets/icons/ticket_blue.png')}
                    />
                    <span style={style.MenuText}>Vé đã mua</span>
                </a>
                <a href='/huong-dan' style={style.MenuSection}>
                    <img
                        style={style.image}
                        src={require('../../assets/icons/question_blue.png')}
                    />
                    <span style={style.MenuText}>Hướng dẫn</span>
                </a>
            </div>
        </div>
    );
};
function FormatCurrency(money) {
    return (money * 1000).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}
const style = {
    image: {
        width: 30, height: 30
    },
    HomeContainer: {
        flex: 1,
        paddingHorizontal: 8,
        paddingBottom: 11,
    },

    HeaderExtend: {
        zIndex: -1,
        backgroundColor: '#E8002A',
        height: 60,
        left: 0,
        width: '100%',
        position: 'absolute',
        borderBottomLeftRadius: 20,
        borderBottomRightRadius: 20,
    },
    AccountInfo: {
        paddingHorizontal: 10,
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingTop: 5,
        flexDirection: 'row',
        display: 'flex',
        paddingBottom: 10,
        // borderBottomWidth:1,
        // borderWidth: 1,
        //borderStyle: 'dashed',
        // borderColor: '#fff',
        borderBottomColor: '#979797',
    },
    TextAccount: {
        fontSize: 12,
        color: '#38ab9e',
        fontFamily: 'Montserrat-SemiBold',

    },
    AccountNavigate: {
        paddingHorizontal: 8,
        paddingTop: 12,
        justifyContent: 'space-between',
        flexDirection: 'row',
        display: 'flex',
    },
    MenuSection: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column'
    },
    MenuText: {
        paddingTop: 3,
        paddingBottom: 5,
        fontFamily: 'Montserrat-SemiBold',
        color: '#393939',
        fontSize: 10
    },
};
export default BoxAccount;