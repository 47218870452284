import React, { Component } from 'react';

import MainLotteryDT from "../MainLotteryDT";
import LotterySelectorV2 from "../../../components/Vietluck/LottertSelectorV2";

import { connect } from "react-redux";
import { lotterySet, lotteryChange } from "../../../redux/actions/lotterySelectorAction";
import LoadingPage from "../../../components/LoadingPage";
import { Helmet } from "react-helmet";
import utils from "../../../utils";
const apiLottery = require("../../../api/Lottery");
const moment = require('moment');
class DT235 extends Component {

    constructor(props) {
        super(props);
        this.productId = 7;

        this.state = {
            isRandom: false,
            isNotifiNextDay: false,
            seo: {}
        }
    }

    async componentDidMount() {
        apiLottery.getProduct(this.productId, (err, result) => {
            if (err) {
                alert(err);
            } else {
                this.props.lotterySet({
                    "productId": this.productId,
                    "termArray": result.term,
                    "term": [result.term[0].termValue],
                    "productOptionArray": result.product.productOptionArray,
                    "priceArray": result.product.priceArray,
                    numberTicket: parseInt(result.product.numberTicket)
                });
                this.setState({ isLoaded: true }, () => {
                    if (window.location.hash) {
                        switch (window.location.hash) {
                            case '#3so': this.props.lotteryChange({ productOptionId: 84 }); break;
                            case '#5so': this.props.lotteryChange({ productOptionId: 85 }); break;
                        }
                    }
                })
            }
        });
        let now = moment();
        let timeClose = moment();
        timeClose.set({ hour: 17, minute: 30, second: 0, millisecond: 0 });
        let timeLoto = moment();
        timeLoto.set({ hour: 18, minute: 30, second: 0, millisecond: 0 });
        if (now.diff(timeClose) >= 0 && now.diff(timeLoto) < 0) {
            this.setState({ isNotifiNextDay: true });
            utils.confirm("Quý khách vui lòng quay lại sau 18h30!\r\nXin cảm ơn!", (check) => {
                this.props.history.goBack()
            })
        }
        var seo = await utils.fetchSeo('235.json');
        this.setState({ seo: seo });
    }

    toggle(component) {
        this.setState({
            [component]: !this.state[component]
        });
    }

    handleRandom() {
        this.setState({ isRandom: true })
    }

    handleValueChange(value) {
        this.setState({ value: value })
    }

    render() {
        if (!this.state.isLoaded) {
            return (
                <LoadingPage />
            )
        }
        return (
            <MainLotteryDT
                onRandom={this.handleRandom.bind(this)}
            >
                {/* <Helmet>
                    <meta charSet="utf-8" />
                    <title>{this.state.seo.title}</title>
                    {
                        this.state.seo.canonical===''?null: <link rel="canonical" href={this.state.seo.canonical} />
                    }
                   
                    <meta
                        name="keywords"
                        content={this.state.seo.keyword}
                    />
                    <meta
                    name="description"
                    content={this.state.seo.description}
                    />
                    <meta property="og:url" content={this.state.seo.url}/>                    
                    <meta property="og:type" content="website"/>
                    <meta property="og:title" content={this.state.seo.title}/>
                    <meta property="og:description" content={this.state.seo.description}/>                    
                    <meta property="og:image" content={this.state.seo.thumb}/>

                </Helmet> */}
                {this.state.isNotifiNextDay ?
                    <p style={{ color: 'red' }}>Thời gian mua vé hôm nay đã hết, Xin quý khách lưu ý!</p> : null}
                <LotterySelectorV2
                    productId={this.props.lotterySelector.productId}
                    productOptionId={this.props.lotterySelector.productOptionId}
                    isRandom={this.state.isRandom}
                    doneRandom={() => this.setState({ isRandom: false })}
                    onValueChange={this.handleValueChange.bind(this)}
                />
            </MainLotteryDT>
        );
    }
}

function mapStateToProps(state) {
    return {
        lotterySelector: state.lotterySelector
    }
}

export default connect(mapStateToProps, { lotterySet, lotteryChange })(DT235);
