import _ from "lodash";
const smalltalk = require('smalltalk');



function alert(msg) {
    smalltalk.alert(
        "VESO888 cho biết",
        msg
    );
}

function confirm(msg, callback) {
    smalltalk.confirm("VESO888 cho biết", msg)
        .then(() => {
            return callback(true);
        })
        .catch(() => {
            return callback(false);
        })
}

const _error_message = {
    "Invalid data": "Dữ liệu không hợp lệ",
    "Incomplete arguments 'attached_file'": "Lỗi tải ảnh lên",
    "Incomplete arguments 'contentType'": "Lỗi tải ảnh lên",
    "Bad path: ticketttttt": "Lỗi",
    "Product not found!": "Loại xổ số không hợp lệ",
    "ProductOption not found!": "Loại vé không hợp lệ",
    'Term not found': "Kỳ mua không hợp lệ",
    "Too many set of numbers!": "Quá nhiều bộ số",
    "Number of select in this option dont fit!": "Bộ số không hợp lệ",
    "Number out of range": "Bộ số không hợp lệ",
    "Product not accept duplicate:": "Bộ số trùng nhau",
    "Price in this option dont fit!": "Số tiền không hợp lệ",
    "Overtime to buy Keno": "Quá thời gian mua vé",
    "Overtime to buy Ticket": "Quá thời gian mua vé",
    "orderShoppingCart not found!": "Đơn hàng không tồn tại",
    "Error Internal": "Lỗi hệ thống",
    "rspCode is false": "Lỗi hệ thống",
    "Order Already Update": "Đơn hàng đã được cập nhật",
    "Fail checksum": "Fail checksum",
    "Error create transactionHistory": "Lỗi tạo lịch sử giao dịch",
    "Not find Wallet": "Thông tin thanh toán không tồn tại",
    "Name shouldn't contain special characters": 'Họ tên không được chưa ký tự đặc biệt',
    "Invalid email": 'Địa chỉ Email không hợp lệ',
    "Invalid gender": 'Giới tính không hợp lệ',
    "Action failed": 'Thao tác lỗi',
    "Password doesn't match": 'Mật khẩu không chính xác',
    "Invalid password": 'Mật khẩu không hợp lệ',
    "Phone number doesn't exist": 'Số điện thoại không tồn tại',
    "Out of sms per day": 'Vượt quá số lần gửi OTP',
    "Out of sms charge": "Vượt quá số lần gửi OTP",
    // "Invalid token": 'Phiên đăng nhập hết hạn',
    "Invalid token": 'Mã OTP không chính xác',
    "Action failed. Pls try again": 'Thao tác thất bại',
    "Unauthorized_account": "Phiên đăng nhập hết hạn",
    "Wrong phone number or password": "Tài khoản hoặc mật khẩu không chính xác",
    "Account is already in use": "Số điện thoại đã tồn tại",
    "Invalid phone number": "Số điện thoại không hợp lệ",
    "Amount not enough": "Tài khoản không đủ để thanh toán",
    "Extension not allow": "Định dạng không được hỗ trợ",
    "Amount must be a positive number": "Số tiền phải lớn hơn 0",
    "Invalid otp code": "Xác thực OTP thất bại",
    "Ur account is already activated!": "Tài khoản đã được kích hoạt",
    "You need agree to the Terms": "Vui lòng đồng ý với điều khoản sử dụng",
    "Failed to send sms": "Liên kết máy chủ OTP thất bại",
    "(Many) Ticket not found!": "Vé không tồn tại",
    "Number should be a string": "Bộ số phải là số",
    'shipment is not accurate': "Địa chỉ thanh toán không hợp lệ",
    "NOT update availableBalance": "Lỗi thanh toán",
    "Not allow Keno and other products in one order": "Không cho phép mua vé Keno chung các vé khác",
    "Term out of range current term": "Vé đã hết hạn kỳ mua. Vui lòng chuyển sang kỳ khác",
    "Too many Term Keno": "Quá nhiều kỳ Keno",
    "TotalPrice not equal": "Giá tiền không chính xác",
    "Keno is not allow shipment": "Keno không hỗ trợ tính năng ship",
    "Amount must be bigger than minimum money": "Số tiền phải lớn hơn định mức",
    "Out of money per time": "Quá số tiền rút mỗi lần",
    "Internal Server Error": "Lỗi hệ thống!",
    "Your account must be activated": "Phiên đăng nhập hết hạn",
    "Spam Detection": "Quá nhiều thao tác"
};

export default function ErrorMessage(error, options = {}) {
    if (error.response) {
        if (error.response.data._error_message) {
            if (error.response.data._error_code === 'custom') {
                confirm(error.response.data._error_message, (check) => {
                })
                return;
            }
            let msg = _error_message[error.response.data._error_message];
            if (msg === undefined) {
                let key = Object.keys(_error_message);
                for (let i = 0; i < key.length; i++) {
                    if (error.response.data._error_message.includes(key[i])) {
                        if (_error_message[key[i]] === "Phiên đăng nhập hết hạn") {
                            confirm("Vui lòng đăng nhập", (check) => {
                                window.location.replace("/auth/sign-in")
                            })
                        } else {
                            alert(_error_message[key[i]]);
                        }
                        return;
                    }
                }
                // alert(`Có lỗi xảy ra!\r\nVui lòng thử lại sau hoặc báo cáo kỹ thuật viên!\r\n${error.response.data._error_message}`);
                // alert(`Vui lòng kiểm tra đường truyền hoặc kết nối lại mạng! \r\n Trân trọng cảm ơn!`);
            } else {
                if (msg === "Vui lòng đăng nhập") {
                    confirm(msg, (check) => {
                        window.location.replace("/sign-in")
                    })
                } else {
                    if (msg === "Phiên đăng nhập hết hạn") {
                        confirm("Vui lòng đăng nhập", (check) => {
                            window.location.replace("/auth/sign-in")
                        })
                    } else {
                        alert(msg);
                    }
                }
            }
        } else {
            // alert(`Có lỗi xảy ra!\r\nVui lòng thử lại sau! Hoặc báo cáo kỹ thuật viên (2)\r\n${safeToString(error.response.data)}`);
            // alert(`Vui lòng kiểm tra đường truyền hoặc kết nối lại mạng!! \r\n Trân trọng cảm ơn!`);
        }
    } else {
        // alert(`Có lỗi xảy ra!\r\nVui lòng thử lại sau! Hoặc báo cáo kỹ thuật viên (3)\r\n${safeToString(error)}`);
        // alert(`Vui lòng kiểm tra đường truyền hoặc kết nối lại mạng!!! \r\n Trân trọng cảm ơn!`);
    }
}

function safeToString(input) {
    try {
        return _.toString(input);
    } catch (e) {
        return "null"
    }
}

