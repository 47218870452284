import React, { Component } from 'react';

import {
    Row, Col
} from "reactstrap";

class Term extends Component {
    constructor(props) {
        super(props);
        this.termContent = {
            subtitle: 'Người dùng vui lòng đọc kỹ Điều khoản sử dụng ("Điều khoản") trước khi tiến hành cài đặt bất kỳ phần nào của Ứng dụng XỔ SỐ TỰ CHỌN ONLINE (bao gồm nhưng không giới hạn các phần mềm, các tệp dữ liệu và tài liệu liên quan) hoặc sử dụng các dịch vụ do bên thứ ba cung cấp để kết nối đến Ứng dụng. Bạn chấp thuận và đồng ý bị ràng buộc bởi các quy định và điều kiện trong Điều khoản này khi thực hiện các thao tác trên đây. Trường hợp bạn không đồng ý với bất kỳ điều khoản sử dụng nào của XỔ SỐ TỰ CHỌN ONLINE (phiên bản này và các phiên bản cập nhật), bạn vui lòng không tải, không cài đặt, không sử dụng Ứng dụng hoặc tháo gỡ Ứng dụng ra khỏi thiết bị di động của bạn.',
            body: [
                {
                    title: 'Giới thiệu về Ứng dụng',
                    subtitle: '',
                    content: [
                        '- Ứng dụng cung cấp dịch vụ Mua hộ vé số – tên gọi tắt là XỔ SỐ TỰ CHỌN ONLINE – là ứng dụng của CÔNG TY TNHH DỊCH VỤ XỔ SỐ TỰ CHỌN ONLINE. XỔ SỐ TỰ CHỌN ONLINE được ủy quyền trả thưởng đối với các vé số trúng thưởng thông qua hệ thống mạng lưới Điểm bán hàng (ĐBH) trên địa bàn các Tỉnh/Thành phố được triển khai kinh doanh vé số) nhằm kết nối người mua vé số với mạng lưới Điểm bán hàng dễ dàng và thuận tiện.',

                        '- Người dùng có thể lựa chọn các số hoặc bộ số (Theo từng loại hình và sản phẩm xổ số phát hành) thông qua ứng dụng XỔ SỐ TỰ CHỌN ONLINE. Sau khi Người dùng xác nhận, XỔ SỐ TỰ CHỌN ONLINE sẽ chuyển thông tin đơn hàng tới điểm bán vé số phù hợp nhất, điểm bán sẽ in vé cho Người dùng và vận chuyển tới tận tay Người dùng (Hiện tại, dịch vụ giao vé tận nơi chưa được áp dụng. XỔ SỐ TỰ CHỌN ONLINE sẽ có thông báo triển khai chính thức trong thời gian tới).',

                        '- Người dùng có thể lựa chọn hình thức uỷ quyền cho XỔ SỐ TỰ CHỌN ONLINE giữ vé mình đã mua, vé đã mua được ghi thông tin cá nhân của Người dùng vào mặt sau (trừ sản phẩm quay số nhanh Keno) và được chụp hình gửi tới Người dùng để xác thực.',

                        '- XỔ SỐ TỰ CHỌN ONLINE không cung cấp dịch vụ mua vé số. Dịch vụ của XỔ SỐ TỰ CHỌN ONLINE là nhằm kết nối giữa Người dùng – là Khách hàng có nhu cầu đặt vé số, với Nhà cung cấp – là Đơn vị có đủ điều kiện pháp lý sẵn sàng cung cấp vé số. XỔ SỐ TỰ CHỌN ONLINE không dự định tiến hành việc cung cấp dịch vụ mua vé số hay thực hiện các hoạt động nào có thể được xem là hoạt động của Người cung cấp dịch vụ mua vé số.',
                    ],
                },
                {
                    title: 'Giới thiệu về sản phẩm',
                    subtitle: '- Hiện tại XỔ SỐ TỰ CHỌN ONLINE đang triển khai cung cấp dịch vụ Mua hộ vé số cho các sản phẩm thuộc loại hình kinh doanh Xổ số tự chọn số điện toán và các sản phẩm xổ số khác qua thiết bị đầu cuối như sau:Người dùng khi sử dụng XỔ SỐ TỰ CHỌN ONLINE để tham gia dự thưởng xổ số điện toán tự chọn phải đáp ứng đầy đủ các điều kiện quy định sau:',
                    content: [
                        '2.1. Xổ số tự chọn số điện toán qua thiết bị đầu cuối (Vé số Vietlott)',
                        '(1) Power 6/55',
                        '- Ngày quay: quay số mở thưởng vào 18h10 - 18h30 các ngày thứ 3, thứ 5 và thứ 7 hàng tuần.',
                        '- Cách chọn số: Chỉ từ 10.000 đồng, chọn 6 số từ 01 - 55 để có cơ hội trúng thưởng Jackpot 1 tối thiểu từ 30 tỷ đồng, Jackpot 2 tối thiểu từ 3 tỷ đồng.',
                        '(2) Mega 6/45',
                        '- Ngày quay: quay số mở thưởng vào 18h10 - 18h30 các ngày thứ 4, thứ 6 và Chủ Nhật hàng tuần.',
                        '- Cách chọn số: Chỉ từ 10.000 đồng, chọn 6 số từ 01 - 45 để có cơ hội trúng thưởng Jackpot tối thiểu từ 12 tỷ đồng.',
                        '(3) Max 4D',
                        '- Ngày quay: quay số mở thưởng vào 18h10 - 18h30 các ngày thứ 3, thứ 5 và thứ 7 hàng tuần.',
                        '- Cách chọn số: Chỉ từ 10.000 đồng, chọn 4 chữ số từ 0000 - 9999 để có cơ hội trúng thưởng gấp 1.500 lần.',
                        '(4) Max 3D',
                        '- Ngày quay: quay số mở thưởng vào 18h10 - 18h30 các ngày thứ 2, thứ 4 và thứ 6 hàng tuần.',
                        '- Cách chọn số: Chỉ từ 10.000 đồng, chọn 3 chữ số từ 000 - 999 để có cơ hội trúng thưởng gấp 100 lần.',
                        '(5) Max 3D+',
                        '- Ngày quay: quay số mở thưởng vào 18h10 – 18h30 các ngày thứ 2, thứ 4 và thứ 6 hàng tuần.',
                        '- Cách chọn số: Chỉ từ 10.000 đồng, chọn 2 bộ số, mỗi bộ số gồm 3 chữ số từ 000 - 999 để có cơ hội trúng thưởng gấp 100.000 lần',
                        '(6) Keno',
                        '- Ngày quay: Keno được phát hành từ 06 giờ 00 phút và kết thúc chậm nhất 21 giờ 50 phút hàng ngày từ Thứ Hai đến Chủ Nhật với tần suất quay số mở thưởng 10 phút/kỳ, bán vé liên tục trong suốt thời gian của mỗi kỳ quay số mở thưởng.',
                        '- Cách chọn số: Chỉ từ 10.000 đồng, chọn các bộ số tương ứng với các bậc từ 01 đến 10 hoặc chẵn hoặc lẻ, lớn hoặc nhỏ.',

                        '2.2. Các sản phẩm Xổ số điện toán khác',
                        '(1) Xổ số điện toán 123',
                        '- Ngày quay: Quay số mở thưởng vào 18h15 – 18h30 hàng ngày.',
                        '- Cách chọn số: Người chơi có thể chọn 1 chuỗi số dự thưởng gồm 3 bộ số, trong đó:',
                        '• Bộ số thứ 1: Là một chữ số bất kì từ 0 đến 9',
                        '• Bộ số thứ 2: Là số có 2 chữ số bất kì từ 00 đến 99',
                        '• Bộ số thứ 3: Là số có 3 chữ số bất kì từ 000 đến 999',
                        '(2) Xổ số điện điện toán Loto 2,3,5 số',
                        '- Ngày quay: Theo kết quả của xổ số kiến thiết Miền Bắc, mở thưởng vào 18h15 đến 18h30 hàng ngày',
                        '- Cách chọn số: Người chơi có thể lựa chọn một trong ba loại hình, trong đó:',
                        '• Loto 2 số: Là một số có 2 chữ số bất kì từ 00 đến 99',
                        '• Loto 3 số: Là một số có 3 chữ số bất kì từ 000 đến 999',
                        '• Loto 5 số: Là một số có 5 chữ số bất kì từ 00000 đến 99999',
                        '(3) Xổ số điện toán 2,3,4 cặp số',
                        '- Ngày quay: Theo kết quả của xổ số kiến thiết Miền Bắc, mở thưởng vào 18h15 đến 18h30 hàng ngày',
                        '- Cách chọn số: Người chơi có thể lựa chọn một trong ba loại hình, trong đó:',
                        '• 2 Cặp số có 2 chữ số từ 00 đến 99',
                        '• 3 Cặp số có 2 chữ số từ 00 đến 99',
                        '• 4 Cặp số có 2 chữ số từ 00 đến 99',
                        '(4) Xổ số điện toán 6/36',
                        '- Cách chọn số: Người chơi có thể lựa chọn 6 bộ số từ 01 - 36',
                    ],
                },
                {
                    title: 'Quy định chung',
                    content: [
                        '- Người dùng khi sử dụng ứng dụng XỔ SỐ TỰ CHỌN ONLINE để tham gia dự thưởng xổ số tự chọn số điện toán phải đáp ứng đầy đủ các điều kiện quy định sau:',
                        '• Là công dân Việt Nam, người Việt Nam định cư ở nước ngoài hoặc người nước ngoài nhập cảnh hợp pháp vào Việt Nam;',
                        '• Là người từ đủ 18 tuổi trở lên;',
                        '• Không phải là người bị hạn chế về năng lực hành vi dân sự hoặc mất năng lực hành vi dân sự theo quy định của pháp luật.',
                        '- Trong trường hợp người chơi lựa chọn Đại lý giữ hộ thì vé sẽ được che một phần mã vạch của vé khi chụp ảnh gửi lại cho người chơi để đảm bảo quyền lợi và an toàn của người chơi trong trường hợp vé của người chơi đặt mua hộ được trúng thưởng.',
                        '- XỔ SỐ TỰ CHỌN ONLINE chịu trách nhiệm trong việc Đại lý bảo quản vé theo đúng quy định của các công ty Xổ số và pháp luật để sẵn sàng hoàn thành nghĩa vụ trả thưởng vé đó cho người sở hữu.',
                    ],
                },
                {
                    title: 'Quy định về đặt vé',
                    content: [
                        '- Khách hàng có thể sử dụng dịch vụ Mua hộ vé số qua ứng dụng XỔ SỐ TỰ CHỌN ONLINE vào các phiên giao dịch trong ngày (trừ các ngày nghỉ, lễ theo thông báo của XỔ SỐ TỰ CHỌN ONLINE) như sau:',
                        '- Sản phẩm Keno: Phiên giao dịch duy nhất từ 8h30 đến 21h50 hàng ngày',
                        '- Các sản phẩm còn lại:',
                        'Phiên giao dịch: từ 0h00 đến 17h40 hàng ngày',
                        '- Đơn hàng sau khi khách hàng đặt vé sẽ được XỔ SỐ TỰ CHỌN ONLINE chuyển đến Đại lý để xử lý in vé và trả lại kết quả xử lý cho khách hàng, cụ thể như sau:',
                        '• Đặt vé sản phẩm Keno sẽ được in vé và gửi cho khách hàng trước kỳ quay số mở thưởng của sản phẩm.',
                        '• Đặt vé trong phiên giao dịch 1: đơn hàng sẽ được xử lý và trả kết quả trước 18h00 ngày quay số mở thưởng của sản phẩm đã đặt.',
                        '- Các trạng thái đơn hàng ghi nhận trên hệ thống:',
                        '• Chờ in vé: Yêu cầu đặt vé đã được hệ thống ghi nhận và gửi đến ĐBH phù hợp nhất để xử lý in vé.',
                        '• Đã in vé: Yêu cầu đặt vé đã được xử lý thành công và Khách hàng nhận được hình ảnh vé đã in tại mục Lịch sử đặt vé. Trường hợp Khách hàng có nhu cầu nhận vé qua email, Khách hàng cập nhật thông tin email tại bước Đặt vé.',
                        '• Lỗi in vé: Yêu cầu đặt vé của khách hàng gặp phải một số vấn đề bất khả kháng dẫn đến không được ghi nhận hoặc ghi nhận không thành công trên hệ thống XỔ SỐ TỰ CHỌN ONLINE. Đối với các trường hợp này, khách hàng sẽ được XỔ SỐ TỰ CHỌN ONLINE hoàn lại tiền trong thời gian được quy định ở mục 7.',
                        '• Hết bộ số: Yêu cầu đặt vé của khách hàng không thành công do bộ số khách hàng chọn mua đã hết theo quy định của các công ty xổ số. Trong trường hợp tài khoản thanh toán đã bị trừ tiền, Khách hàng sẽ được hoàn lại tiền trong thời gian được quy định ở mục 7.',
                        '- Định nghĩa đơn hàng:',
                        '• Đơn hàng thành công: các đơn hàng ở trạng thái Đã in vé.',
                        '• Đơn hàng không thành công: các đơn hàng ở trạng thái Lỗi in vé, Hết bộ số.',
                    ],
                },
                {
                    title: 'Quy định về thanh toán',
                    content: [
                        'Người dùng thanh toán giá trị đơn hàng, bao gồm:',
                        '(1) Giá trị của các bộ số đã chọn (được bán đúng với giá niêm yết theo quy định của các công ty xổ số và Bộ tài chính),',
                        '(2) Chi phí vận chuyển (áp dụng khi Người dùng lựa chọn hình thức giao vé tận tay)',
                        '(3) Chi phí lưu trữ vé khi Người dùng chọn hình thức Đại lý lưu trữ, bảo quản hộ vé.',
                    ],
                },
                {
                    title: 'Quy định về hủy đặt vé',
                    content: [
                        '- Các yêu cầu đặt vé đã được ghi nhận trên hệ thống XỔ SỐ TỰ CHỌN ONLINE, khách hàng không được phép hủy đơn hàng. XỔ SỐ TỰ CHỌN ONLINE sẽ chỉ xử lý đặt hộ vé cho các đơn hàng đã thanh toán thành công.',
                    ],

                },
                {
                    title: 'Quy định về bồi hoàn',
                    content: [
                        '7.1. Các giao dịch bồi hoàn là các giao dịch bị yêu cầu xử lý hoàn tiền. Các giao dịch này nằm trong các trường hợp sau:',
                        '   (1) Giao dịch bồi hoàn theo ghi nhận từ hệ thống của XỔ SỐ TỰ CHỌN ONLINE: các giao dịch đặt vé mà hệ thống XỔ SỐ TỰ CHỌN ONLINE ghi nhận ở các trạng thái Lỗi in vé, vé không in kịp trước giờ quay do số lượng tăng đột biến, hết bộ số như Quy định về đặt vé nêu trên.',
                        '   (2) Giao dịch bồi hoàn theo khiếu nại từ khách hàng: Người dùng không nhận được hàng hóa, dịch vụ (sản phẩm) đã thanh toán, hoặc hàng hóa, dịch vụ được giao không đúng như mô tả: Người dùng nhận được sản phẩm đã thanh toán, nhưng sản phẩm được giao không đáp ứng mô tả của XỔ SỐ TỰ CHỌN ONLINE tại thời điểm thanh toán.',
                        '   (3) Giao dịch được ghi nhận lỗi từ hệ thống của đơn vị thanh toán: Các giao dịch đặt vé của khách hàng đã bị trừ tiền nhưng không được ghi nhận thanh toán thành công hoặc có lỗi trong quá trình truyền kết quả thanh toán thành công sang hệ thống của XỔ SỐ TỰ CHỌN ONLINE.',
                        '   (4) Giao dịch trái phép: Giao dịch bị hoàn tiền do khiếu nại của khách hàng là chủ thẻ, chủ tài khoản thanh toán xác định có gian lận, giả mạo khi thực hiện giao dịch.',
                        '7.2. XỔ SỐ TỰ CHỌN ONLINE sẽ xử lý các yêu cầu bồi hoàn theo quy định như sau:',
                        '   • Các yêu cầu bồi hoàn nằm trong trường hợp (1): Thời gian hoàn trả số dư tối đa không quá 24h00 làm việc kể từ thời điểm đặt vé.',
                        '   • Các yêu cầu bồi hoàn nằm trong trường hợp (2), (3), (4): Thời gian hoàn trả số dư tối đa không quá 72h00 làm việc kể từ thời điểm đặt vé.',

                    ],
                },
                {
                    title: 'Quy định về lưu trữ vé',
                    content: [
                        'Các đơn hàng lựa chọn hình thức “Đại lý giữ hộ” và được in thành công sẽ được lưu trữ tại kho vé của XỔ SỐ TỰ CHỌN ONLINE như sau:',
                        '- Vé không trúng thưởng',
                        '• Các vé không trúng thưởng của sản phẩm Keno được lưu trong vòng 24h kể từ ngày QSMT, sau 24h XỔ SỐ TỰ CHỌN ONLINE sẽ tiến hành hủy vé',
                        '• Các vé không trúng thưởng đối với các sản phẩm khác được lưu trữ trong vòng 60 ngày kể từ ngày QSMT, sau 60 ngày XỔ SỐ TỰ CHỌN ONLINE sẽ tiến hành hủy vé',
                        '- Vé trúng thưởng',
                        '• Các vé trúng thưởng có giá trị trong phạm vi XỔ SỐ TỰ CHỌN ONLINE được trả thưởng và sẽ làm thủ tục trả thưởng theo yêu cầu cho khách hàng, tiến hành lưu trữ và bảo quản vé thưởng này theo quy định của Nhà điều hành và không bàn giao lại vé trúng thưởng đã được trả thưởng thành công cho khách hàng.',
                        '• Đối với các vé trúng thưởng có giá trị không trong phạm vi XỔ SỐ TỰ CHỌN ONLINE trả thưởng – XỔ SỐ TỰ CHỌN ONLINE sẽ thực hiện bàn giao vé cho khách và hướng dẫn khách hàng làm thủ tục nhận thưởng theo Quy định tại mục 10 của Điều khoản này.',

                    ],
                },
                {
                    title: 'Quy định về chuyển thưởng',
                    content: [
                        'Áp dụng trong trường hợp vé đặt hộ qua ứng dụng của XỔ SỐ TỰ CHỌN ONLINE được trúng thưởng và Khách hàng lựa chọn hình thức uỷ quyền cho Đại lý bảo quản hộ vé.',
                        'Nguyên tắc chuyển thưởng:',
                        '- Theo quy định của nhà cung cấp (Vietlott và các công ty xổ số khác), các yêu cầu chuyển thưởng hợp lệ là yêu cầu chuyển thưởng phát sinh trong vòng 60 (sáu mươi) ngày kể từ ngày quay số mở thưởng. XỔ SỐ TỰ CHỌN ONLINE sẽ tự động trừ khoản thuế thu nhập cá nhân theo quy định của Pháp luật.',
                        '- Thời gian xử lý yêu cầu chuyển thưởng: không quá 3 ngày làm việc (trừ thứ 7, chủ nhật, ngày lễ…) kể từ khi nhận được yêu cầu của khách hàng.',
                        'Quy định chuyển thưởng:',
                        ' - Với các vé trúng thưởng có trị giá trúng thưởng từ 10 triệu đồng/vé trở xuống (≤ 10 triệu đồng): Hệ thống tự động cộng tiền vào tài khoản trúng thưởng của Khách hàng. Lúc này Khách hàng có thể lựa chọn chuyển thưởng qua các Kênh thanh toán theo quy định như sau:',
                        '(1) Số tiền yêu cầu chuyển thưởng phải là bội số của 10.000 đồng và tối thiểu là 10.000 đồng/yêu cầu;',
                        '(2) Theo quy định của XỔ SỐ TỰ CHỌN ONLINE và các Kênh thanh toán, số tiền tối đa cho một yêu cầu chuyển thưởng là 10 triệu đồng/yêu cầu;',
                        '(3) Mức phí áp dụng cho mỗi yêu cầu chuyển thưởng được áp dụng theo từng thời điểm cụ thể, phù hợp theo quy định của từng Kênh thanh toán.',
                        '- Với các vé trúng thưởng có trị giá trúng thưởng trên 10 triệu đồng/vé: Khách hàng liên hệ trực tiếp với bộ phận hotline của XỔ SỐ TỰ CHỌN ONLINE theo số điện thoại 0835.556.556   để được hướng dẫn chuyển thưởng.',
                        '- Các Trạng thái trong quá trình chuyển thưởng cập nhật để Khách hàng nhận biết:',
                        '• Chờ chuyển thưởng: Yêu cầu chuyển thưởng của Khách hàng đã được tiếp nhận và chuyển tới bộ phận xử lý chuyển thưởng của XỔ SỐ TỰ CHỌN ONLINE.',
                        '• Đã chuyển thưởng: Yêu cầu chuyển thưởng của Khách hàng đã được xử lý thành công. Khách hàng kiểm tra và xác nhận số dư của tài khoản nhận chuyển thưởng.',
                        'Với các trường hợp Khách hàng lựa chọn hình thức giao vé tận nơi: Khách hàng vui lòng mang vé số trúng thưởng tới trực tiếp các ĐBH được phép kinh doanh vé số tự chọn số điện toán để nhận thưởng. Thời gian nhận thưởng trong vòng 60 ngày kể từ ngày QSMT.',
                    ],
                },
                {
                    title: 'Quy định về việc in vé, ghi thông tin và gửi hình ảnh vé',
                    content: [
                        'Quy định về việc in Áp dụng trong trường hợp Người dùng lựa chọn hình thức Ủy quyền cho đại lý giữ hộ:',
                        '- Đối với các vé tham gia dự thưởng của sản phẩm xổ số Vietlott:',
                        '• Vé dự thưởng Mega 6/45; Power 6/55; Max 3D+ vé sau khi được in ra sẽ được ghi các thông tin: Mã đơn hàng, CMND/CCCD/Hộ chiếu của Người dùng lên mặt sau của vé.',
                        '• Vé dự thưởng Max 3D và Max4D vé sau khi được in ra sẽ được ghi thông tin Mã đơn hàng lên mặt trước của vé.',
                        '• Đối với vé tham gia dự thưởng của Keno khách hàng sẽ nhận được vé ngay sau khi mua vé nhưng không ghi thông tin khách hàng trên vé.',
                        '- Đối với các vé tham gia dự thưởng của sản phẩm xổ số khác: Vé dự thưởng Xổ số điện toán 123; Xổ số điện toán Loto 2,3,5 số; Xổ số điện toán 2,3,4 cặp số; Xổ số điện toán 6/36 vé sau khi được in ra sẽ được ghi các thông tin: Mã đơn hàng, CMND/CCCD/Hộ chiếu của Người dùng lên mặt sau của vé.',
                        '- Vé của Người dùng sau khi được in ra và ghi thông tin theo đúng quy định, sẽ được chụp hình ảnh chi tiết hai mặt hoặc một mặt tùy từng loại sản phẩm theo quy định của XỔ SỐ TỰ CHỌN ONLINE và gửi lại cho Người dùng qua Ứng dụng và email đăng kí của Người dùng (Khuyến nghị: Để nhận được thông báo thông tin kịp thời, Người dùng lưu ý cập nhật địa chỉ email đang thường xuyên sử dụng).',
                        '- Khi nhận được hình ảnh thực tế của vé vật lý đã in, Người dùng, trong thời gian quy định (10 phút), cần kiểm tra lại các thông tin trên vé vật lý và Báo lỗi vé nếu có (Hotline 0904788555) bất kỳ sai sót nào (vé in sai số, sai thông tin, …) để XỔ SỐ TỰ CHỌN ONLINE xử lý. XỔ SỐ TỰ CHỌN ONLINE chỉ có thể xử lý vé trước giờ khóa máy của các công ty Xổ số kỳ xổ ngày hôm đó. Và nếu có tranh chấp, khiếu nại giá trị của vé cuối cùng chính là vé vật lý đã in cho Người dùng ( xin rất lưu ý vấn đề này).',
                        ' Chiếc vé vật lý được in ra, ghi thông tin, chụp ảnh gửi Người dùng và được xác nhận là chính xác về mặt thông tin là chứng từ duy nhất có giá trị pháp lý để Người dùng so thưởng và nhận thưởng. Thông tin được ghi trên vé được lấy từ kênh thanh toán (Ví) của khách hàng đã đăng ký. Người dùng chịu trách nhiệm hoàn toàn về tính chính xác của thông tin mà Người dùng cung cấp cho XỔ SỐ TỰ CHỌN ONLINE và XỔ SỐ TỰ CHỌN ONLINE sẽ không chịu trách nhiệm với những sai sót đối với những thông tin do Người dùng cung cấp sai này.',
                        '- Khi phát hiện thông tin cá nhân khai báo ban đầu của mình bị sai, Người dùng có thể gọi điện cho bộ phận CSKH của XỔ SỐ TỰ CHỌN ONLINE hoặc của Ví sử dụng để được hướng dẫn cách thay đổi lại thông tin đúng của mình trên Ví. Sau khi thông tin được cập nhật thành công trên Ví, Người dùng tiếp tục sử dụng dịch vụ của XỔ SỐ TỰ CHỌN ONLINE thông tin sau khi thay đổi sẽ có giá trị kể từ lần mua vé tiếp theo.',

                    ],
                },
                {
                    title: 'Quy định về tiếp nhận và xử lý khiếu nại',
                    content: [
                        '- XỔ SỐ TỰ CHỌN ONLINE tiếp nhận khiếu nại về chất lượng dịch vụ cung cấp từ Khách hàng qua các kênh:\n',
                        '• Hotline: 0835.556.556',

                        '-Thời gian tiếp nhận khiếu nại:',
                        '• Qua hotline: Từ 08h00 đến 18h00 hàng ngày (trừ thứ 7, chủ nhật hoặc ngày lễ theo quy định).',
                        '• Các kênh khác: 24/24 giờ hàng ngày (không trừ thứ 7, chủ nhật hoặc ngày lễ)',
                        '-Thời gian xử lý khiếu nại:',
                        '• Hỗ trợ xử lý ngay khi tiếp nhận yêu cầu với các trường hợp có thể hỗ trợ.',
                        '• Đối với các trường hợp cần xác minh thông tin, XỔ SỐ TỰ CHỌN ONLINE sẽ kiểm tra và phản hồi phương án xử lý tối đa không quá 03 (ba) ngày làm việc, kể từ khi tiếp nhận khiếu nại.',

                    ],
                },
                {
                    title: 'Giới hạn trách nhiệm',
                    content: [
                        'Người dùng đồng ý bảo vệ, bồi thường, giữ cho XỔ SỐ TỰ CHỌN ONLINE và các công ty liên kết của XỔ SỐ TỰ CHỌN ONLINE không bị tổn hại bởi bất kỳ khiếu nại, khiếu kiện, chi phí, tổn thất, thiệt hại, phán quyết của toà án và các phí tổn khác (nếu có), bao gồm nhưng không giới hạn phí luật sư, liên quan đến hoặc phát sinh từ việc vi phạm điều khoản sử dụng hoặc bất kỳ sự sử dụng của Người dùng, hoặc bất kỳ người nào khác sử dụng dịch vụ thông qua Người dùng, hoặc sử dụng điện thoại, máy tính của Người dùng.\n',
                        'XỔ SỐ TỰ CHỌN ONLINE giữ quyền, bằng chi phí của mình, đảm nhận hoàn toàn việc bảo vệ và kiểm soát (nhưng không có trách nhiệm) đối với bất kỳ vấn đề nào phát sinh như vậy tuỳ thuộc vào sự bồi thường của Người dùng.',
                        '- Người dùng đồng ý cho phép XỔ SỐ TỰ CHỌN ONLINE sử dụng dữ liệu cá nhân của mình (Bao gồm tên, số liên lạc) và chuyển giao những dữ liệu này đến Nhà cung cấp phục vụ cho mục đích cung cấp dịch vụ. Người dùng đồng thời cũng cho phép XỔ SỐ TỰ CHỌN ONLINE được tự do chuẩn bị, thu thập, lưu trữ và cập nhật dữ liệu cá nhân trong phạm vi và thời gian cần thiết trong quá trình XỔ SỐ TỰ CHỌN ONLINE cung cấp dịch vụ ứng dụng.',
                        '- XỔ SỐ TỰ CHỌN ONLINE có thể điều chỉnh các Điều khoản sử dụng dịch vụ nói trên tùy theo thời gian mà không cần thông báo trước.',
                        '- XỔ SỐ TỰ CHỌN ONLINE không chịu trách nhiệm cho bất cứ sự thất thoát, hư hại, khiếu nại hoặc phí tổn nào bao gồm các thất thoát hoặc hư hại gián tiếp hoặc ngẫu nhiên cho thiết bị di động hoặc các ứng dụng trên thiết bị này của Người dùng xảy ra do việc cài đặt hoặc sử dụng Ứng dụng XỔ SỐ TỰ CHỌN ONLINE.',
                        '- Người dùng phải chấp nhận một cách đầy đủ và vô điều kiện nội dung hiện hành của các Điều kiện sử dụng cho từng lần sử dụng dịch vụ.',
                        '- Những điều kiện sử dụng này sẽ được điều chỉnh bởi Pháp luật Việt Nam và Người dùng được quyền đệ trình lên cơ quan thực thi Pháp luật của Tòa án có thẩm quyền của Việt Nam.',
                    ],
                },
                {
                    title: 'Quy định về bảo mật',
                    content: [
                        '- Đối với XỔ SỐ TỰ CHỌN ONLINE:\n',
                        '• XỔ SỐ TỰ CHỌN ONLINE cam kết sử dụng các phương thức truyền tin an toàn và mã hóa để truyền tải, lưu trữ các thông tin cá nhân và giao tiếp của Khách hàng.',
                        '• XỔ SỐ TỰ CHỌN ONLINE cam kết bảo mật toàn bộ các thông tin mà quý Khách hàng cung cấp và không tiết lộ thông tin cho đơn vị khác trừ khi có yêu cầu từ Cơ quan Nhà nước có thẩm quyền theo quy định của pháp luật.',
                        '• XỔ SỐ TỰ CHỌN ONLINE cam kết không sử dụng thông tin Khách hàng vào các mục đích khác khi không có sự đồng ý từ Khách hàng.',
                        '• XỔ SỐ TỰ CHỌN ONLINE được miễn trừ trách nhiệm về việc thông tin cá nhân, thông tin giao dịch và các thông tin có liên quan của khách hàng đến dịch vụ XỔ SỐ TỰ CHỌN ONLINE cung cấp bị công bố/công khai cho bất kỳ bên thứ tư nào trong các trường hợp khách quan ngoài tầm kiểm soát của XỔ SỐ TỰ CHỌN ONLINE và đơn vị cung cấp Kênh thanh toán (bị thâm nhập trái phép, thiên tai, địch họa, chiến tranh... hoặc trở ngại khách quan;...).',
                        '\n',
                        '- Đối với khách hàng:',
                        '• Khi sử dụng dịch vụ của XỔ SỐ TỰ CHỌN ONLINE, Khách hàng sẽ luôn phải chịu trách nhiệm với mọi giao dịch xuất phát từ tài khoản của mình.',
                        '• Khách phải bồi thường cho XỔ SỐ TỰ CHỌN ONLINE và/hoặc bên thứ ba khác có liên quan nếu Quý khách vi phạm quy định trong Điều khoản sử dụng dịch vụ này gây tổn thất, thiệt hại cho XỔ SỐ TỰ CHỌN ONLINE hoặc cho bên thứ ba khác có liên quan.',
                        '• Khách hàng phải tự chịu trách nhiệm trong trường hợp cho người khác mượn, sử dụng tài khoản của mình để sử dụng dịch vụ của XỔ SỐ TỰ CHỌN ONLINE và/hoặc vô tình để người khác lợi dụng lấy thông tin tài khoản của mình để sử dụng cho các mục đích khác.',
                        '• Khách hàng không được sử dụng hệ thống của XỔ SỐ TỰ CHỌN ONLINE để đăng tải, chuyển, truyền hoặc lưu trữ các thông tin vi phạm pháp luật, vi phạm thuần phong mỹ tục của dân tộc hay thực hiện các giao dịch trái phép như rửa tiền, giao dịch đáng ngờ khác.',
                    ],
                },
                {
                    title: 'Cập nhật',
                    content: [
                        'Điều khoản này có thể được cập nhật thường xuyên bởi Công ty cổ phần XỔ SỐ TỰ CHỌN ONLINE phiên bản cập nhật sẽ được chúng tôi công bố tại trang đặt vé. Phiên bản cập nhật sẽ thay thế cho các quy định và điều khoản trong thỏa thuận ban đầu.\n',
                    ],
                },
                {
                    title: 'Liên hệ',
                    content: [
                        'CÔNG TY TNHH DỊCH VỤ XỔ SỐ TỰ CHỌN ONLINE',
                        '\n',
                        'Địa chỉ: 148 Lương Đình Của Hà Nội',
                        'Hotline: 0904788555',
                        '\n',
                    ],
                },
            ],
        };
    }


    render() {
        return (
            <div className="bootstrap-reset">
                {/* <div style={{width: "100%", height: "100%", position: "absolute", top: 0, left: 0}} className="d-flex justify-content-center align-items-center">
                    <img width={"200px"} height={"200px"} src={"/img/logo/logo-background.png"}  alt="avatar"/>
                </div> */}
                <div className="d-flex justify-content-center align-items-center font-weight-bold" style={{ fontSize: "16px", top: 10, textAlign: 'center' }}>
                    ĐIỀU KHOẢN SỬ DỤNG DỊCH VỤ XỔ SỐ TỰ CHỌN ONLINE
                </div>
                <div className="p-2">
                    Người dùng vui lòng đọc kỹ Điều khoản sử dụng ("Điều khoản") trước khi tiến hành sử dụng dịch vụ Mua hộ vé số (bao gồm nhưng không giới hạn các phần mềm, các file và tài liệu liên quan). Bạn chấp thuận và đồng ý bị ràng buộc bởi các quy định và điều kiện trong Điều khoản sử dụng dịch vụ này khi thực hiện các thao tác trên đây.
                </div>
                <div className="p-2">
                    {
                        this.termContent.body.map(({ title, subtitle, content }, index) => (
                            <div key={index} className="mt-2">
                                <Row className="font-weight-bold">{index + 1}. {title}</Row>
                                <Row>
                                    <Col>
                                        {
                                            content.map((text, index2) => (
                                                <Row key={`${index}-${index2}`}>
                                                    {text}
                                                </Row>
                                            ))
                                        }
                                    </Col>
                                </Row>
                            </div>
                        ))
                    }
                </div>
            </div>
        );
    }
}

export default Term;
