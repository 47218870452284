import React, { Component } from 'react';

import ListItem from "../../components/Vietluck/ListItem";

import { Link } from "react-router-dom";

const listWithdrawalMethod = [
    // {
    //     title: "Rút tiền tại quầy",
    //     icon: require("../../assets/images/icon/exchange.png"),
    //     type: 11,
    //     //childrenInput: <Withdrawal_Store onSubmit={this.handleSubmit.bind(this)}/>
    // },
    {
        title: "Rút tiền về tài khoản ngân hàng",
        icon: require("../../assets/images/icon/atm.png"),
        type: 2
        // childrenInput: <Withdrawal_Bank onSubmit={this.handleSubmit.bind(this)}/>
    },
    ,
    {
        title: "Rút về ví hệ thống để mua vé",
        icon: require("../../assets/images/logo/logo.png"),
        type: 17
        // childrenInput: <Withdrawal_Momo onSubmit={this.handleSubmit.bind(this)}/>
    }
    // {
    //     title: "Rút tiền về ví Momo",
    //     icon: require("../../assets/images/icon/momo.png"),
    //     type: 13
    //     // childrenInput: <Withdrawal_Momo onSubmit={this.handleSubmit.bind(this)}/>
    // },
    // {
    //     title: "Rút tiền về ví Viettel Pay",
    //     icon: require("../../assets/images/icon/viettelpay.png"),
    //     type: 13
    //     // childrenInput: <Withdrawal_Momo onSubmit={this.handleSubmit.bind(this)}/>
    // },
    // {
    //     title: "Rút tiền về ví Zalo Pay",
    //     icon: require("../../assets/images/icon/zalopay.png"),
    //     type: 13
    //     // childrenInput: <Withdrawal_Momo onSubmit={this.handleSubmit.bind(this)}/>
    // },
    // {
    //     title: "Nạp tiền điện thoại",
    //     icon: require("../../assets/images/icon/card-phone-money.png"),
    //     type: 14
    //     // childrenInput: <Withdrawal_Phone onSubmit={this.handleSubmit.bind(this)}/>
    // }
];

class Withdrawal extends Component {
    render() {
        return (
            <div className="p-2">
                <div>
                    VESO888 hỗ trợ người dùng rút tiền qua hình thức sau:
                </div>
                {
                    listWithdrawalMethod.map((l, i) => (
                        <Link to={`/withdrawal-items#${l.type}`} key={i} className="link-unset">
                            <ListItem
                                avatar={l.icon}
                                title={l.title}
                            />
                        </Link>
                    ))
                }
            </div>
        );
    }
}

export default Withdrawal;
